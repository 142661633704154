import React, { SVGAttributes } from 'react';

export function RightArrow(props: SVGAttributes<unknown>): JSX.Element {
  return (
    <svg width='7' height='10' viewBox='0 0 7 10' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M1.38393 9.43311C1.19462 9.22187 1.19502 8.87982 1.38483 8.66913L4.68633 5.00445L1.39375 1.32984C1.20445 1.11857 1.20488 0.776527 1.39471 0.565855C1.58454 0.355183 1.89188 0.355661 2.08118 0.566925L5.54496 4.4326C5.61243 4.50787 5.6662 4.59747 5.70267 4.69575C5.73919 4.79415 5.75792 4.8996 5.75779 5.00606C5.75766 5.11252 5.73868 5.21791 5.70192 5.3162C5.6652 5.41436 5.61148 5.50352 5.54383 5.57859L2.0704 9.43411C1.88059 9.6448 1.57325 9.64435 1.38393 9.43311Z'
        fill='#BCCAD2'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M1.32307 9.50067C1.10023 9.25202 1.1007 8.8494 1.32413 8.6014L4.56477 5.00426L1.33288 1.39738C1.11006 1.14871 1.11057 0.746095 1.33401 0.498116C1.55745 0.250137 1.91922 0.250701 2.14204 0.499376L5.60582 4.36505C5.68127 4.4492 5.74134 4.54935 5.78212 4.65923C5.82295 4.76925 5.84389 4.88715 5.84375 5.00619C5.8436 5.12522 5.82238 5.24306 5.78127 5.35296C5.74023 5.46272 5.68016 5.5624 5.60451 5.64634L2.13111 9.50184C1.90768 9.74984 1.54591 9.74932 1.32307 9.50067ZM1.44553 8.73686C1.28933 8.91025 1.289 9.19172 1.44479 9.36556C1.60059 9.53939 1.8535 9.53976 2.0097 9.36638L5.48286 5.51114C5.54253 5.44494 5.59018 5.36601 5.62256 5.27944C5.65498 5.19276 5.67172 5.09981 5.67183 5.00593C5.67195 4.91204 5.65543 4.81905 5.62322 4.73227C5.59106 4.6456 5.54387 4.56683 5.48437 4.50045L2.02031 0.634473C1.86453 0.460622 1.61162 0.460229 1.4554 0.633595C1.29919 0.80696 1.29884 1.08844 1.45461 1.26229L4.74719 4.9369C4.78071 4.9743 4.78064 5.03487 4.74703 5.07218L1.44553 8.73686ZM4.79707 5.12808C4.83067 5.09077 4.88508 5.09083 4.91861 5.12821C4.95213 5.16561 4.95236 5.22649 4.91876 5.26381C4.88517 5.30112 4.83075 5.30106 4.79722 5.26368C4.7637 5.22628 4.76347 5.1654 4.79707 5.12808ZM4.79737 4.88114C4.76386 4.84373 4.76394 4.78317 4.79755 4.74587C4.83118 4.70859 4.88589 4.70837 4.91939 4.74579C4.9529 4.7832 4.95282 4.84375 4.91921 4.88105C4.88558 4.91833 4.83087 4.91855 4.79737 4.88114Z'
        fill='#BCCAD2'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M1.14853 9.65709C0.845791 9.31928 0.846368 8.78155 1.15 8.44452L4.24969 5.00384L1.15833 1.55379C0.855616 1.21596 0.856234 0.678226 1.15989 0.341226C1.47665 -0.0103171 2.00085 -0.00941233 2.3166 0.34297L5.78033 4.2086C5.87606 4.31539 5.95118 4.44117 6.00185 4.57768C6.05254 4.71427 6.0783 4.85992 6.07812 5.00647C6.07795 5.15303 6.05183 5.2986 6.0008 5.43506C5.94983 5.57135 5.87469 5.69665 5.7786 5.80325L2.30524 9.65872C1.98851 10.0103 1.46431 10.0094 1.14853 9.65709ZM4.98488 5.12435C5.00036 5.14813 5.00631 5.17348 5.00635 5.1954C5.0064 5.22272 4.99726 5.25602 4.97124 5.28492C4.96693 5.2897 4.96226 5.29423 4.95725 5.29846C4.93124 5.32047 4.89624 5.33455 4.85763 5.33451C4.84768 5.3345 4.83798 5.33356 4.82861 5.33178M4.82937 4.67739L4.90345 4.76007L4.92174 4.78049C4.94986 4.81186 4.97099 4.84748 4.98514 4.88524C5.00062 4.86141 5.00664 4.83592 5.00667 4.81378C5.0067 4.7861 4.99739 4.75317 4.97196 4.72478C4.96766 4.71997 4.963 4.71543 4.95802 4.71119M4.98837 4.89425C4.98598 4.89316 4.98357 4.89209 4.98115 4.89105C4.97828 4.89502 4.97512 4.89893 4.97168 4.90275M4.98837 4.89425C4.99536 4.91466 5.00035 4.93563 5.00333 4.95685C5.00776 4.98843 5.00776 5.02064 5.00331 5.05222C5.00029 5.07354 4.99524 5.09472 4.98817 5.11522C4.98573 5.11633 4.98328 5.11741 4.98082 5.11846M4.87869 4.81907L4.86454 4.83174L4.87869 4.81907ZM4.84326 5.19582L4.8578 5.18277L4.84326 5.19582ZM4.87258 5.19607L4.85803 5.20912L4.87258 5.19607Z'
        fill='#BCCAD2'
      />
    </svg>
  );
}
