/* eslint-disable @typescript-eslint/naming-convention */
import { UrlObject } from 'url';

export enum BREAD_CRUMB_ROUTES {
  HOME = '/',
  DISPENSARIES_COUNTRY_STATE_CITY = '/[countryCode]/dispensaries/[stateCodeAndCityName]',
  DISPENSARIES = '/dispensaries',
  DISPENSARY = '/dispensary/[cName]',
  CATEGORY = '/dispensary/[cName]/products/[category]',
  CATEGORIES = '/dispensary/[cName]/categories',
  PRODUCTS = '/dispensary/[cName]/products',
  SUBCATEGORY = '/dispensary/[cName]/products/[category]/[subcategory]',
  BRANDS = '/dispensary/[cName]/brands',
  BRAND = '/dispensary/[cName]/brands/[brandId]',
  INFO = '/dispensary/[cName]/info',
  SPECIALS = '/dispensary/[cName]/specials',
}

export type Crumb = {
  label: string;
  route?: string;
  href?: string;
  urlProps?: UrlObject;
};

export type DispensaryInfo = {
  name: string;
  cName: string;
  city?: string;
  locationHref: string | null;
};
